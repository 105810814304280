import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { DeeploopOcta } from "../components/icons"

import { Row, Col } from "reactstrap"

import { cx } from "emotion"
import { Space, Grid, Background, Typo, SiteColors } from "../styles"

const UseCasePage = () => (
  <div>
    <SEO title="Use Cases" />
    <div className={cx(Grid.useCaseGrid)} style={{
        position:"absolute",
        height:"100%",
        width:"100%",
        top:0,
        left:0,
        right:0,
        bottom:0
      }}>
      
      <div className={cx(Background.grey, Space.pl3, Space.pr3, Space.pt4, Space.pb4)}>
        <Link to="/">
          <DeeploopOcta color={SiteColors.primary.text} style={{width:35,height:35}} />
        </Link>
        <div className={cx(Typo.bold,Space.mt2)}>Use Case Finder</div>
      </div>

      {/* LEFT */}
      <div>
        
      </div>

      {/* RIGHT */}
      <div>
        
      </div>

    </div>
  </div>
)

export default UseCasePage
